import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';

const MainFloorAndFootingElevationSurveys = () => (
  <Layout
    title="Finished Floor Elevation Surveys in Calgary and Red Deer | Main Floor and Footing Elevation Surveys "
    description="Looking for reliable finished floor elevation surveys? Axiom Geomatics provides expert services in Calgary to ensure compliance and accurate construction results."
    keywords="main floor elevation survey, verification, certification, footing check"
  >
    <main>
      <h1>Main Floor & Footing Elevation Surveys Calgary & Area</h1>
      <p>
        A <strong>Main Floor Elevation Survey</strong> measures the height of the main floor of the
        building under construction to ensure that it has been built to the correct height. This
        survey should be completed as the floor joists and main floor sheeting are being put down.
      </p>

      <p>
        A <strong>Footing Elevation Survey</strong> measures the height of the footing — the
        concrete outline upon which basement walls are poured. This survey is completed very early
        in the construction state.
      </p>

      <p>
        Depending upon the age of the neighbourhood, the local municipal bylaws and whether this is
        new construction or a renovation, either the Main Floor Survey or the Footing Elevation
        Survey will be required, not both.
      </p>

      <p>
        These surveys are particularly important as a structure built too low may have sewage issues
        (e.g. it won't drain properly). Structures that are too high look out of place relative to
        their neighbours additionally they may violate the maximum height allowed for a structure.
        Each municipality sets its own tolerance for how high or low a structure may be relative to
        the approved{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/development-permits">
          Development Permit
        </a>{' '}
        and{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/plot-plans">Plot Plan</a>.
      </p>

      <p>
        When completed Axiom Geomatics will provide a Main Floor Elevation Certificate indicating
        that the survey's completeness and findings.
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#too-high">What happens if my main floor is too high?</Link>
        </li>
        <li>
          <Link to="#too-low">What happens if my main floor is too low?</Link>
        </li>
        <li>
          <Link to="#really-need">Do I need a Main Floor or Footing Elevation Survey?</Link>
        </li>
      </ul>

      <h3 id="too-high">What happens if my main floor is too high?</h3>
      <p>
        If the structure exceeds the maximum height the roof may need to be re-archited to be
        flatter. In most cases the builder is able to remediate the existing structure so it is
        within acceptable tolerance levels, however this varies on a case-by-case basis.
      </p>

      <h3 id="too-low">What happens if my main floor is too low?</h3>
      <p>
        Too low may cause conflicts with water/sewer/gas/electrical lines that go under the
        foundation. In most cases the builder is able to remediate the existing structure so it is
        within acceptable tolerance levels, however this varies on a case-by-case basis.
      </p>

      <h3 id="really-need">Do I need a Main Floor or Footing Elevation Survey?</h3>
      <p>This depends on which municipality you live in.</p>
    </main>
  </Layout>
);

export default MainFloorAndFootingElevationSurveys;
